import validate from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_xfjhaootkybgctlzkemtplki5m/node_modules/nuxt/dist/pages/runtime/validate.js";
import authentication_45global from "/opt/render/project/src/apps/hub/client/middleware/authentication.global.ts";
import manifest_45route_45rule from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.9_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_xfjhaootkybgctlzkemtplki5m/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authentication_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "invitation-signup": () => import("/opt/render/project/src/apps/hub/client/middleware/invitation-signup.ts")
}